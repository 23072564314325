<template>
	<div class="ActivityAudit">
		<w-navTab titleText="特殊任务"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header-nav">
				<div :class="['header-nav-item', {'item-active': navIndex == item.id}]" v-for="(item,index) in navList"
					@click="onClickNav(index)">
					{{item.title}}
				</div>
			</div>
			<div class="body">
				<div class="body-search" v-if="navIndex == 2">
					<div class="body-search-icon">
						<img src="@/assets/img/u_icon125.png" alt="">
					</div>
					<div class="body-search-input">
						<input type="text" v-model="keyword" placeholder="请输入要查询的内容" />
					</div>
					<div class="body-search-button" @click="onSearch">搜索</div>
				</div>
				<div class="body-nav" v-if="navIndex == 2">
					<div class="body-nav-item" @click="isShowDepartment = true">
						{{departmentText}}
						<van-icon name="arrow-up" v-if="isShowDepartment" />
						<van-icon name="arrow-down" v-else />
					</div>
					<div class="body-nav-item" @click="isShowWhether = true">
						{{whetherText}}
						<van-icon name="arrow-up" v-if="isShowWhether" />
						<van-icon name="arrow-down" v-else />
					</div>
				</div>
				<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="body-list" v-for="(item,index) in activityAuditList" @click="$router.push({ name: 'ActivityAuditDetail', params:{id: item.activity_id}})">
					<div class="body-header">
						<div class="body-header-title">
							{{item.title}}
						</div>
						<div class="body-header-bottom">
							<div class="header-bottom-item">{{item.group_name}}</div>
							<div class="header-bottom-item item-color-red">{{item.county_name}}</div>
						</div>
					</div>
					<div class="body-content">
						<div class="body-content-item">
							<div class="body-item-label">活动时间：</div>
							<div class="body-item-text">
								{{convertTime(item.begin_date,item.begin_time)}} - {{convertTime(item.finish_date,item.finish_time)}}
							</div>
						</div>
						<div class="body-content-item">
							<div class="body-item-label">打卡位置：</div>
							<div class="body-item-text">{{item.address}}</div>
						</div>
						<div class="body-content-item">
							<div class="body-item-label">打卡范围：</div>
							<div class="body-item-text">{{item.distance}}米</div>
						</div>
					</div>
					<div class="body-footer">
						<div class="body-footer-left">申请日期：{{item.apply_audit_time}}</div>
						<div class="body-footer-button" v-if="navIndex == 1">待审核</div>
					</div>
					<div class="body-item-icon" v-if="navIndex == 2">
						<img src="@/assets/img/u_icon126.png" alt="" v-if="item.audit_status == 2">
						<img src="@/assets/img/u_icon127.png" alt="" v-if="item.audit_status == 3">
					</div>
				</div>
				</VantList>
			</div>
		</div>
		<van-popup v-model="isShowDepartment" position="bottom">
			<van-picker show-toolbar value-key="name" :default-index="departmentIndex"
				:columns="departmentData" @confirm="onConfirmDepartment" @cancel="isShowDepartment = false" />
		</van-popup>
		
		<van-popup v-model="isShowWhether" position="bottom">
			<van-picker show-toolbar value-key="district_name" :default-index="whetherIndex" :columns="whetherData"
				@confirm="onConfirmWhether" @cancel="isShowWhether = false" />
		</van-popup>
	</div>
</template>

<script>
	import User from '@/api/user';
	import Event from '@/store/event';
	import VantVendor from '@/vendor/vant';
	import VantList from '@/components/VantList';
	import LoadingPage from '@/components/LoadingPage';
	import Moment from '@/vendor/moment';
	
	export default {
		name: 'ActivityAudit',
		data() {
			return {
				navIndex: 1,
				navList: [{
					id: 1,
					title: '未审核'
				}, {
					id: 2,
					title: '已审核'
				}],

				departmentIndex: 0,
				departmentText: '全部区县',
				isShowDepartment: false,
				departmentData: [],
				
				whetherIndex: 0,
				whetherText: '全部状态',
				isShowWhether: false,
				whetherData: ['全部状态', '已同意', '已拒绝'],
				
				loadingPage: true,
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				activityAuditList: [],
				departmentId: '',
				status: 0,
				keyword: ''
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'ActivityAudit')
			this.onLoad()
			this.getDistrictList()
			if (this.navIndex == 1) {
				Event.$on('removeItem', itemId => {
					let itemIndex = this.activityAuditList.findIndex(item => item.activity_id == itemId)
					this.activityAuditList.splice(itemIndex, 1)
					if (this.activityAuditList.length <= 0) {
						this.isEmpty = true;
					}
				})
			}
			
		},
		methods: {
			onLoad() {
				this.loading = true;
				if(this.navIndex == 1){
					
					let params = {
						page: this.paramsPage,
						limit: this.paramsLimit,
					};
					
					User.activityAuditAwait(params)
						.then(result => {
							this.paramsPage++;
							this.activityAuditList = this.activityAuditList.concat(result.data.list);
							
							this.loading = false;
							
							this.loadingPage = false
							
							if (this.activityAuditList.length <= 0) {
								this.isEmpty = true;
							}
								
							if (result.data.list.length < this.paramsLimit) {
								this.finished = true;
							}
						})
						.catch(error => {
							VantVendor.Toast.fail(error.msg);
						});
				}else{
					
					let params = {
						page: this.paramsPage,
						limit: this.paramsLimit,
						keyword: this.keyword,
						county_id: this.departmentId,
						status: this.status
					};
					
					User.activityAuditAudited(params)
						.then(result => {
							this.paramsPage++;
							this.activityAuditList = this.activityAuditList.concat(result.data.list);
							
							this.loading = false;
							
							this.loadingPage = false
							
							if (this.activityAuditList.length <= 0) {
								this.isEmpty = true;
							}
								
							if (result.data.list.length < this.paramsLimit) {
								this.finished = true;
							}
						})
						.catch(error => {
							VantVendor.Toast.fail(error.msg);
						});
				}
				
			},
			// 区县
			getDistrictList() {
				User.county()
					.then(result => {
						this.departmentData = result.data
						let params = {
							id: 0,
							name: '全部区县'
						}
						this.departmentData.unshift(params)
					})
					.catch(error => {
						console.log(error);
					});
			},
			onClickNav(index) {
				this.navIndex = this.navList[index].id
				this.getPublic()
			},
			// 搜索
			onSearch() {
				this.getPublic()
			},
			// 选择所属部门
			onConfirmDepartment(e, value) {
				this.departmentId = e.id
				this.departmentText = e.name
				this.departmentIndex = value
				this.isShowDepartment = false
				this.getPublic()
			},
			// 选择是否同意
			onConfirmWhether(e, value) {
				this.whetherText = e
				this.whetherIndex = value
				this.status = value
				this.isShowWhether = false
				this.getPublic()
			},
			convertTime(time1,time2){
				let time = time1 + ' ' + time2
				return Moment(time).format('YYYY-MM-DD HH:mm:ss')
			},
			getPublic(){
				this.paramsPage = 1
				this.activityAuditList = []
				this.finished = false
				this.isEmpty = false
				this.onLoad()
			}
		},
		components: {
			VantList,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.ActivityAudit {
		.container {
			.header-nav {
				padding-top: 11px;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				background-color: #fff;

				.header-nav-item {
					padding-bottom: 8px;
					color: #999;
					text-align: center;
					font-size: 15px;
					border-bottom: 2px solid #fff;
					box-sizing: border-box;
					margin: 0 41px;
					line-height: 23px;

					&.item-active {
						color: #333;
						font-weight: bold;
						border-bottom: 2px solid #37F;
						box-sizing: border-box;
					}
				}
			}

			.body {
				padding: 12px 16px;
				box-sizing: border-box;

				.body-search {
					padding: 8px 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-radius: 46px;
					background: #FFF;
					box-sizing: border-box;
					margin-bottom: 12px;

					.body-search-icon {
						width: 16px;
						height: 16px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.body-search-input {
						flex: 1;
						margin: 0 8px;

						input {
							width: 100%;
							border: 0;
						}
					}

					.body-search-button {
						color: #37F;
						font-size: 14px;
						line-height: 20px;
					}
				}

				.body-nav {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 12px;

					.body-nav-item {
						padding: 3px 6px;
						margin: 0 20px;
						border-radius: 4px;
						border: 1px solid #E0E0E3;
						background: #FFF;
						box-sizing: border-box;
						color: #4E586F;
						font-size: 14px;
						line-height: 20px;
					}
				}

				.body-list {
					padding: 12px 12px 10px;
					box-sizing: border-box;
					border-radius: 8px;
					background: #fff;
					margin-bottom: 12px;
					position: relative;

					&.body-list:last-child {
						margin-bottom: 0;
					}

					.body-header {
						border-bottom: 1px solid #F2F2F2;
						padding-bottom: 12px;
						box-sizing: border-box;

						.body-header-title {
							color: #333;
							font-size: 17px;
							line-height: 26px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.body-header-bottom {
							display: flex;
							align-items: center;
							margin-top: 4px;

							.header-bottom-item {
								padding: 3px 8px;
								color: #37F;
								font-size: 14px;
								line-height: 14px;
								border-radius: 29px;
								background: rgba(51, 119, 255, 0.10);
								box-sizing: border-box;
								margin-right: 8px;

								&.item-color-red {
									color: #FF6969;
									background: rgba(255, 105, 105, 0.10);
								}
							}
						}
					}

					.body-content {
						padding: 12px 0 12px;
						border-bottom: 1px solid #F2F2F2;
						box-sizing: border-box;

						.body-content-item {
							display: flex;
							margin-bottom: 8px;

							&.body-content-item:last-child {
								margin-bottom: 0;
							}

							.body-item-label {
								width: 80px;
								min-width: 80px;
								color: #999;
								font-size: 15px;
								line-height: 23px;
							}

							.body-item-text {
								color: #333;
								font-size: 15px;
								line-height: 23px;
							}
						}
					}

					.body-footer {
						padding-top: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;

						.body-footer-left {
							color: #999;
							font-size: 14px;
							line-height: 21px;
						}

						.body-footer-button {
							padding: 4px 14px;
							color: #FFF;
							font-size: 15px;
							line-height: 23px;
							border-radius: 16px;
							background: #37F;
							box-sizing: border-box;
						}
					}

					.body-item-icon {
						position: absolute;
						right: 16px;
						bottom: 12px;
						width: 80px;
						height: 80px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
	}
</style>